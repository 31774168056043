<script setup lang="ts">
  import {ref} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';

  withDefaults(defineProps<{
      placeholder?: string,
      modelValue: string,
      blueVersion?: boolean
      readonly?: boolean
  }>(), {
      placeholder: undefined,
      readonly: false,
  });

  const emit = defineEmits<{
    (e: 'change', value: string): void
    (e: 'submit', value: string): void
    (e: 'update:modelValue', value: string): void
    (e: 'clear'): void
    (e: 'blur'): void
  }>();
  const inputElement = ref();

  const {b, e, em} = useCpBem('cp-searchbar');

  const clear = () => {
    inputElement.value.focus();
    emit('update:modelValue', '');
    emit('clear');
    emit('change', '');
  };

  const focus = () => {
    inputElement.value.focus();
  };

  const change = ($event: InputEvent) => {
    const val = ($event.target as any).value;
    emit('update:modelValue', val);
    emit('change', val);
  };

  defineExpose({clear, focus});
</script>
<template>
    <div :class="b" data-testid="search-bar-component">
        <CpIconStandard :class="e('icon')" icon="search" :type="blueVersion ? 'primary' : 'neutral'" />
        <form :class="e('form')" data-testid="search-form" @submit.prevent="emit('submit', modelValue)">
            <!-- Please don't change the id, we use it to focus the input from the outside -->
            <input
                id="cp-search-bar"
                ref="inputElement"
                :value="modelValue"
                :placeholder="placeholder ? `Buscar en ${placeholder}` : ''"
                :class="em('input', {'blue': blueVersion!})"
                :readonly="readonly"
                type="search"
                enterkeyhint="search"
                @input="change"
                @blur="$emit('blur')"
            >
        </form>
        <div v-if="modelValue && modelValue.length > 0">
            <CpIconStandard
                icon="cancel"
                :type="blueVersion ? 'primary' : 'neutral'"
                :active-color="blueVersion ? 'primary-dark' : 'neutral-dark'"
                @click="clear()"
            />
        </div>
    </div>
</template>
<style scoped lang="scss">
  .cp-searchbar {
    display: flex;
    background-color: var(--color-white);
    border: $cp-border-neutral;
    padding: $cp-gutter-xsmall;
    border-radius: $cp-gutter-xlarge;
    gap: $cp-gutter-xsmall;

    &__form {
      width: 100%;
    }

    &:focus-within {
      border-color: var(--color-primary);
      color: var(--color-primary);
    }

    &__input {
      width: 100%;
      color: var(--color-neutral-dark);
      font-size: var(--cp-body-regular-size);
      line-height: var(--cp-body-regular-line-height);
      font-family: var(--cp-font-family);

      &:focus{
        outline: none;
      }
      &::placeholder {
        color: var(--color-neutral);
      }
      &--blue{
        color: var(--color-primary);
      }
      &:placeholder-shown {
        text-overflow: ellipsis;
      }
    }
    &__icon {
      color: inherit;
    }
  }
</style>
